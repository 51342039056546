import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  authToken: null,
  image: null,
  role: null,
  name: null,
}

const adminUserSlice = createSlice({
  name: "adminUser",
  initialState,
  reducers: {
    setAdminUser(_, action) {
      return action.payload
    },
    clearAdminUser() {
      return null
    },
  },
})

// createSlice creates actions for us from our reducer defs
export const { setAdminUser, clearAdminUser } = adminUserSlice.actions
export default adminUserSlice.reducer
