import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.GATSBY_STUDIO_HUB_API_BASEURL,
    prepareHeaders(headers) {
      headers.set("authToken", process.env.GATSBY_CONTENT_HUB_AUTH_TOKEN)
      return headers
    },
  }),
  endpoints(builder) {
    return {
      getContentBlocksDetailsByCategorySlugAndDate: builder.query({
        query({ slug, category, date }) {
          return {
            url: "/blocks/details",
            method: "POST",
            body: {
              slug,
              category,
              date,
            },
          }
        },
      }),
    }
  },
})

export const { useGetContentBlocksDetailsByCategorySlugAndDateQuery } = apiSlice
