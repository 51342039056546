import { createSlice } from "@reduxjs/toolkit"

export interface UserState {
  email: string | null
  phone: string | null
  shareUrl: string | null
  ambassadorPermission: boolean
}

const initialState: UserState = {
  email: null,
  phone: null,
  shareUrl: null,
  ambassadorPermission: false,
}

const activeUserSlice = createSlice({
  name: "activeUser",
  initialState,
  reducers: {
    setActiveUser(state, action) {
      return {
        ...state,
        ...action.payload,
        // ambassadorPermission:
        //   (!!state.email || !!action.payload.email) &&
        //   (!!state.shareUrl || !!action.payload.shareUrl),
      }
    },
    clearActiveUser() {
      return initialState
    },
  },
})

// createSlice creates actions for us from our reducer defs
export const { setActiveUser, clearActiveUser } = activeUserSlice.actions
export default activeUserSlice.reducer
