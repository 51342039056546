import { combineReducers } from "@reduxjs/toolkit"
import activeUserReducer from "redux/features/activeUserSlice"
import adminUserReducer from "redux/features/adminUserSlice"
import { ambassadorApi } from "redux/features/ambassadorApiSlice"
import { apiSlice } from "redux/features/apiSlice"
import modalReducer from "redux/features/modalSlice"
import themeReducer from "redux/features/themeSlice"

const rootReducer = combineReducers({
  theme: themeReducer,
  activeUser: activeUserReducer,
  adminUser: adminUserReducer,
  modalIsOpen: modalReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
  [ambassadorApi.reducerPath]: ambassadorApi.reducer,
})

export default rootReducer
