import type { GatsbyBrowser } from "gatsby"
// eslint-disable-next-line import/default, @typescript-eslint/no-unused-vars
import React from "react"
import BrowserPageWrapper from "./src/components/PageWrapper"
import RootWrapper from "./src/components/RootWrapper"

/*************************************************
 Wraps all pages of our app in our DonutStateProviderComponent (declared below)
 which contains our UserContext provider.
 https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#wrapPageElement
 **************************************************/
export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => {
  return <RootWrapper>{element}</RootWrapper>
}

/*************************************************
 Wraps all pages of our app in our custom PageWrapper component.
 https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#wrapPageElement
**************************************************/
export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
  props,
}) => <BrowserPageWrapper {...props}>{element}</BrowserPageWrapper>
