// COLORS
export const DARK_MODE__BKG = "#1a1418" // Gray 10
export const LIGHT_MODE__BKG_ALT = "#f8f4ed" // Coffee 2

// Gray
export const COLOR__GRAY__1 = "#fcfafb"
export const COLOR__GRAY__2 = "#f7f5f6"
export const COLOR__GRAY__3 = "#ebe6e8"
export const COLOR__GRAY__4 = "#dbd7d9"
export const COLOR__GRAY__5 = "#c4bec2"
export const COLOR__GRAY__6 = "#948b90"
export const COLOR__GRAY__7 = "#5c5257"
export const COLOR__GRAY__8 = "#453940"
export const COLOR__GRAY__9 = "#2e252a"
export const COLOR__GRAY__10 = "#1a1418"

// Coffee
export const COLOR__COFFEE__1 = "#fcfbf7"
export const COLOR__COFFEE__2 = "#f8f4ed"
export const COLOR__COFFEE__3 = "#ede8df"
export const COLOR__COFFEE__4 = "#ded8ce"
export const COLOR__COFFEE__5 = "#c9c2b5"
export const COLOR__COFFEE__6 = "#968d7d"
export const COLOR__COFFEE__7 = "#5c5345"
export const COLOR__COFFEE__8 = "#453d2f"
export const COLOR__COFFEE__9 = "#2e2619"
export const COLOR__COFFEE__10 = "#141009"

// Pink Panther
export const COLOR__PINK_PANTHER__1 = "#fff8fc"
export const COLOR__PINK_PANTHER__2 = "#feecf6"
export const COLOR__PINK_PANTHER__3 = "#ffd4eb"
export const COLOR__PINK_PANTHER__4 = "#ffa3d5"
export const COLOR__PINK_PANTHER__5 = "#ff73be"
export const COLOR__PINK_PANTHER__6 = "#f84faa"
export const COLOR__PINK_PANTHER__7 = "#d6368c"
export const COLOR__PINK_PANTHER__8 = "#b52874"
export const COLOR__PINK_PANTHER__9 = "#871c56"
export const COLOR__PINK_PANTHER__10 = "#521134"

// Flamingo
export const COLOR__FLAMINGO__1 = "#fff8f7"
export const COLOR__FLAMINGO__2 = "#ffebe8"
export const COLOR__FLAMINGO__3 = "#fcd1cc"
export const COLOR__FLAMINGO__4 = "#f7aaa1"
export const COLOR__FLAMINGO__5 = "#ed8072"
export const COLOR__FLAMINGO__6 = "#e45e4e"
export const COLOR__FLAMINGO__7 = "#c44839"
export const COLOR__FLAMINGO__8 = "#a8372a"
export const COLOR__FLAMINGO__9 = "#78271d"
export const COLOR__FLAMINGO__10 = "#4c1812"

// Honey
export const COLOR__HONEY__1 = "#fefcf2"
export const COLOR__HONEY__2 = "#fcf6d9"
export const COLOR__HONEY__3 = "#faedb5"
export const COLOR__HONEY__4 = "#f8e79c"
export const COLOR__HONEY__5 = "#f5db69"
export const COLOR__HONEY__6 = "#f0c91f"
export const COLOR__HONEY__7 = "#d2ae0b"
export const COLOR__HONEY__8 = "#ae9007"
export const COLOR__HONEY__9 = "#6a5a13"
export const COLOR__HONEY__10 = "#423707"

// Tech Teal
export const COLOR__TECH_TEAL__1 = "#f5fdfb"
export const COLOR__TECH_TEAL__2 = "#e7fbf7"
export const COLOR__TECH_TEAL__3 = "#c1f5e9"
export const COLOR__TECH_TEAL__4 = "#91edd9"
export const COLOR__TECH_TEAL__5 = "#6ee0c7"
export const COLOR__TECH_TEAL__6 = "#49d4b5"
export const COLOR__TECH_TEAL__7 = "#31b598"
export const COLOR__TECH_TEAL__8 = "#26947c"
export const COLOR__TECH_TEAL__9 = "#196a58"
export const COLOR__TECH_TEAL__10 = "#104236"

// Sky
export const COLOR__SKY__1 = "#f7fdff"
export const COLOR__SKY__2 = "#eafaff"
export const COLOR__SKY__3 = "#c9f1ff"
export const COLOR__SKY__4 = "#98e4ff"
export const COLOR__SKY__5 = "#62d6ff"
export const COLOR__SKY__6 = "#31c9ff"
export const COLOR__SKY__7 = "#0baae3"
export const COLOR__SKY__8 = "#078bba"
export const COLOR__SKY__9 = "#036587"
export const COLOR__SKY__10 = "#004057"

// Periwinkle
export const COLOR__PERIWINKLE__1 = "#f6f6ff"
export const COLOR__PERIWINKLE__2 = "#eaeafe"
export const COLOR__PERIWINKLE__3 = "#ccc4ff"
export const COLOR__PERIWINKLE__4 = "#a38fff"
export const COLOR__PERIWINKLE__5 = "#7d55ff"
export const COLOR__PERIWINKLE__6 = "#5822f3"
export const COLOR__PERIWINKLE__7 = "#4b1dcf"
export const COLOR__PERIWINKLE__8 = "#3e18aa"
export const COLOR__PERIWINKLE__9 = "#2c1179"
export const COLOR__PERIWINKLE__10 = "#1b0b4b"

// Chocolate
export const COLOR__CHOCOLATE__1 = "#faf5f5"
export const COLOR__CHOCOLATE__2 = "#f2e8e6"
export const COLOR__CHOCOLATE__3 = "#decac5"
export const COLOR__CHOCOLATE__4 = "#ba9a93"
export const COLOR__CHOCOLATE__5 = "#94695f"
export const COLOR__CHOCOLATE__6 = "#6c3c31"
export const COLOR__CHOCOLATE__7 = "#613329"
export const COLOR__CHOCOLATE__8 = "#4f281f"
export const COLOR__CHOCOLATE__9 = "#381d16"
export const COLOR__CHOCOLATE__10 = "#2b150f"

// GENERAL
export const EASTER_EGG_SECRET = "thedonut"
export const BREAKPOINTS_MIN = {
  mobile: "(min-width: 576px)",
  tablet: "(min-width: 768px)",
  tabletLarge: "(min-width: 900px)",
  desktop: "(min-width: 1200px)",
  desktopLarge: "(min-width: 1600px)",
}

export const BREAKPOINTS_MIN_MAX = {
  mobile: "(max-width: 575px)",
  mobileLg: "(min-width: 576px) and (max-width: 767px)",
  tablet: "(min-width: 768px) and (max-width: 899px)",
  tabletLarge: "(min-width: 900px) and (max-width: 1199px)",
  desktop: "(min-width: 1200px) and (max-width: 1599px)",
}

// AMBASSADOR
export const AMBASSADOR_BASEPATH = "/ambassadors/admin"

// SLICKTEXT
export const SLICKTEXT_NUMBER = "844-647-0141"

// CAMPAIGN MONITOR
export const CM_DAILY_LISTID = "dd4b770b511bedd1b0b437e485674b84"
export const CM_POSITIVE_LISTID = "6d1e0a7c102ee3b1809cf42e8cd0c710"

export const SELECT_STATE_OPTIONS = [
  {
    value: "AL",
    label: "Alabama",
  },
  {
    value: "AK",
    label: "Alaska",
  },
  {
    value: "AZ",
    label: "Arizona",
  },
  {
    value: "AR",
    label: "Arkansas",
  },
  {
    value: "CA",
    label: "California",
  },
  {
    value: "CO",
    label: "Colorado",
  },
  {
    value: "CT",
    label: "Connecticut",
  },
  {
    value: "DE",
    label: "Delaware",
  },
  {
    value: "DC",
    label: "District Of Columbia",
  },
  {
    value: "FL",
    label: "Florida",
  },
  {
    value: "GA",
    label: "Georgia",
  },
  {
    value: "ID",
    label: "Idaho",
  },
  {
    value: "IL",
    label: "Illinois",
  },
  {
    value: "IN",
    label: "Indiana",
  },
  {
    value: "IA",
    label: "Iowa",
  },
  {
    value: "KS",
    label: "Kansas",
  },
  {
    value: "KY",
    label: "Kentucky",
  },
  {
    value: "LA",
    label: "Louisiana",
  },
  {
    value: "ME",
    label: "Maine",
  },
  {
    value: "MD",
    label: "Maryland",
  },
  {
    value: "MA",
    label: "Massachusetts",
  },
  {
    value: "MI",
    label: "Michigan",
  },
  {
    value: "MN",
    label: "Minnesota",
  },
  {
    value: "MS",
    label: "Mississippi",
  },
  {
    value: "MO",
    label: "Missouri",
  },
  {
    value: "MT",
    label: "Montana",
  },
  {
    value: "NE",
    label: "Nebraska",
  },
  {
    value: "NV",
    label: "Nevada",
  },
  {
    value: "NH",
    label: "New Hampshire",
  },
  {
    value: "NJ",
    label: "New Jersey",
  },
  {
    value: "NM",
    label: "New Mexico",
  },
  {
    value: "NY",
    label: "New York",
  },
  {
    value: "NC",
    label: "North Carolina",
  },
  {
    value: "ND",
    label: "North Dakota",
  },
  {
    value: "OH",
    label: "Ohio",
  },
  {
    value: "OK",
    label: "Oklahoma",
  },
  {
    value: "OR",
    label: "Oregon",
  },
  {
    value: "PA",
    label: "Pennsylvania",
  },
  {
    value: "RI",
    label: "Rhode Island",
  },
  {
    value: "SC",
    label: "South Carolina",
  },
  {
    value: "SD",
    label: "South Dakota",
  },
  {
    value: "TN",
    label: "Tennessee",
  },
  {
    value: "TX",
    label: "Texas",
  },
  {
    value: "UT",
    label: "Utah",
  },
  {
    value: "VT",
    label: "Vermont",
  },
  {
    value: "VA",
    label: "Virginia",
  },
  {
    value: "WA",
    label: "Washington",
  },
  {
    value: "WV",
    label: "West Virginia",
  },
  {
    value: "WI",
    label: "Wisconsin",
  },
  {
    value: "WY",
    label: "Wyoming",
  },
]
