import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
export const ambassadorApi = createApi({
  reducerPath: "ambassadorApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.GATSBY_AMBASSADOR_API_BASEURL,
    prepareHeaders: (headers, { getState }) => {
      headers.set("authToken", process.env.GATSBY_AMBASSADOR_API_AUTH_TOKEN)
      return headers
    },
  }),
  tagTypes: ["Participants"],
  endpoints(builder) {
    return {
      getParticipantDetails: builder.query({
        query: ({ participantId }) => ({
          url: `/participant/${participantId}`,
          method: "GET",
        }),
        providesTags: ["Participants"],
        // providesTags: result => {
        //   return result
        //     ? [{type: 'Participants', id: result._id}]
        //     : [{type: 'Participants', id: 'error'}];
        // },
      }),
      updateParticipant: builder.mutation({
        query: ({ participantId, data }) => ({
          url: `/participant/${participantId}`,
          method: "PUT",
          body: data,
        }),
        invalidatesTags: ["Participants"],
        // invalidatesTags: (res, error, {participantId}) => {
        //   if (!!error) return;
        //   return [{type: 'Participants', id: participantId}];
        // },
      }),
      updateFulfillmentReward: builder.mutation({
        query: ({ fulfillmentId, rewardId }) => ({
          url: `/fulfillment/reward/${fulfillmentId}`,
          method: "PUT",
          body: {
            reward: rewardId,
          },
        }),
        invalidatesTags: ["Participants"],
      }),
      addParticipant: builder.mutation({
        query: data => ({
          url: `/participant`,
          method: "PUT",
          body: data,
        }),
        invalidatesTags: ["Participants"],
      }),
      getParticipantByEmail: builder.mutation({
        query: data => ({
          url: `/participant`,
          method: "POST",
          body: data,
        }),
        invalidatesTags: ["Participants"],
      }),
      getMilestones: builder.query({
        query: () => ({
          url: `/milestone/all`,
          method: "GET",
        }),
        providesTags: ["Milestones"],
      }),
    }
  },
})

export const {
  useGetParticipantDetailsQuery,
  useUpdateParticipantMutation,
  useUpdateFulfillmentRewardMutation,
  useGetMilestonesQuery,
  useAddParticipantMutation,
  useGetParticipantByEmailMutation,
} = ambassadorApi
