import { configureStore } from "@reduxjs/toolkit"
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist"
import storage from "redux-persist/lib/storage"
import { ambassadorApi } from "./features/ambassadorApiSlice"
import { apiSlice } from "./features/apiSlice"
import rootReducer from "./rootReducer"

// Redux persist configuration
const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: ["api", "ambassadorApi", "modalIsOpen"],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  // Passing an object to 'reducer' does what 'combineReducers'
  // did for us, and adds a chunk of state in the store called "theme"
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(apiSlice.middleware)
      .concat(ambassadorApi.middleware),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export const persistor = persistStore(store)
